import { IconName } from "@components/library/Icon/Icon";
import { RfpStatus } from "@tsTypes/rfps";
import { Request, Rfp } from "@tsTypes/schema";
import dayjs from "dayjs";

export const getRfpProgramName = (
  rfpProgramIdentifier: string | undefined | null
): IconName | undefined => {
  switch (rfpProgramIdentifier) {
    case "agriculture": {
      return "Agriculture";
    }
    case "hygiene": {
      return "Hygiene";
    }
    case "food": {
      return "Food";
    }
    case "materials": {
      return "Materials";
    }
    case "medicine": {
      return "Medicine";
    }
    case "water": {
      return "Water";
    }
    default: {
      return undefined;
    }
  }
};

export const checkRfpCompanyHidden = (rfp, user) => {
  if (!rfp.company.company_name) {
    return true;
  }
  if (!rfp.confidential) {
    return false;
  }
  const rfpProposals = user.profile_info?.proposals?.filter(
    (proposal) => proposal.request_for_proposal_id === rfp.id
  );
  if (rfpProposals?.length) {
    for (const proposal of rfpProposals) {
      if (!checkRfpCompanyHiddenForProposal(rfp, proposal)) return false;
    }
  }
  return true;
};

export const checkRfpCompanyHiddenForProposal = (rfp, proposal) => {
  if (!rfp?.confidential) {
    return false;
  }
  return true;
};

/**
 * @deprecated (justinpchang) Use getLaunchableRequestStatus instead
 */
export const getRfpStatus = (request) => {
  const isBeforeLaunchDate = dayjs().isBefore(request.requestable.launch_date ?? 0);
  const isPastDeadline =
    Boolean(request.requestable.deadline) && dayjs().isAfter(request.requestable.deadline ?? 0);

  if (isBeforeLaunchDate) return RfpStatus.COMING_SOON;
  if (request.enabled)
    if (isPastDeadline) return RfpStatus.IN_EXTENSION;
    else return RfpStatus.ACTIVE;
  return RfpStatus.CLOSED;
};

export const getRfpStatusText = (request, { comingSoon, active, inExtension, pastDeadline }) => {
  switch (getRfpStatus(request)) {
    case RfpStatus.COMING_SOON: {
      return comingSoon;
    }
    case RfpStatus.ACTIVE: {
      return active;
    }
    case RfpStatus.IN_EXTENSION: {
      return inExtension;
    }
    case RfpStatus.CLOSED: {
      return pastDeadline;
    }
    default: {
      return null;
    }
  }
};

export const sortRfps = <
  T extends Pick<Request, "enabled"> & {
    requestable: Pick<Rfp, "display_priority" | "deadline" | "launch_date"> &
      Record<string, unknown>;
  } & Record<string, unknown>
>(
  rfps: T[],
  withDisplayPriority = false
): T[] => {
  const sortByDisplayPriority = (_rfps: T[]) =>
    _rfps.sort(
      (a, b) =>
        b.requestable.display_priority - a.requestable.display_priority ||
        new Date(a.requestable.deadline!).getTime() - new Date(b.requestable.deadline!).getTime() ||
        new Date(a.requestable.launch_date!).getTime() -
          new Date(b.requestable.launch_date!).getTime()
    );

  const activeRfps = rfps.filter((rfp) => getRfpStatus(rfp) === RfpStatus.ACTIVE);
  if (withDisplayPriority) sortByDisplayPriority(activeRfps);
  else
    activeRfps.sort(
      (a, b) =>
        new Date(a.requestable.deadline!).getTime() - new Date(b.requestable.deadline!).getTime()
    );

  const comingSoonRfps = rfps.filter((rfp) => getRfpStatus(rfp) === RfpStatus.COMING_SOON);
  if (withDisplayPriority) sortByDisplayPriority(comingSoonRfps);
  else
    comingSoonRfps.sort(
      (a, b) =>
        new Date(a.requestable.launch_date!).getTime() -
        new Date(b.requestable.launch_date!).getTime()
    );

  const pastDeadlineRfps = rfps.filter((rfp) => getRfpStatus(rfp) === RfpStatus.IN_EXTENSION);
  if (withDisplayPriority) sortByDisplayPriority(pastDeadlineRfps);
  else
    pastDeadlineRfps.sort(
      (a, b) =>
        new Date(b.requestable.deadline!).getTime() - new Date(a.requestable.deadline!).getTime()
    );

  const inactiveRfps = rfps.filter((rfp) => getRfpStatus(rfp) === RfpStatus.CLOSED);
  if (withDisplayPriority) sortByDisplayPriority(inactiveRfps);
  else
    inactiveRfps.sort(
      (a, b) =>
        new Date(b.requestable.deadline!).getTime() - new Date(a.requestable.deadline!).getTime()
    );

  return [...activeRfps, ...comingSoonRfps, ...pastDeadlineRfps, ...inactiveRfps];
};
