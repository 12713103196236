import { Tooltip } from "@components/library";
import { COLORS } from "@constants";
import styled from "styled-components";
import Icon, { IconName } from "../Icon/Icon";
import { BaseButtonProps } from "./Button";

interface Props extends BaseButtonProps {
  iconName: IconName;
  tooltipText?: string;
  tooltipWidth?: string;
  tooltipPosition?: "top" | "bottom";
  hideTooltip?: boolean;
  variant?:
    | "primary"
    | "secondary"
    | "ghost"
    | "fourth"
    | "fifth"
    | "ghost-white"
    | "destructive-secondary"
    | "destructive-ghost"
    | "magic";
  size?: "xs" | "sm" | "md";
  margin?: string | 0;
  shouldPortalTooltip?: boolean;
  "data-testid"?: string;
}

const IconButton = ({
  iconName,
  tooltipText,
  tooltipWidth,
  tooltipPosition,
  hideTooltip = false,
  variant = "primary",
  size = "sm",
  margin = 0,
  shouldPortalTooltip = false,
  id,
  name,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  "data-testid": dataTestId,
}: Props) => {
  let color = COLORS.WHITE;
  if (variant.includes("destructive")) {
    color = COLORS.RED;
  } else if (variant === "secondary" || variant === "ghost") {
    color = COLORS.HALO_BLUE;
  } else if (variant === "fourth" || variant === "fifth") {
    color = COLORS.BLACK;
  } else if (variant === "magic") {
    color = COLORS.PINK_800;
  }

  const iconButton = (
    <Button
      variant={variant}
      size={size}
      margin={margin}
      id={id}
      name={name}
      type="button"
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid={dataTestId}
    >
      <Icon name={iconName} size="md" color={color} />
    </Button>
  );

  return hideTooltip ? (
    iconButton
  ) : (
    <Tooltip
      content={tooltipText ?? iconName ?? ""}
      tooltipWidth={tooltipWidth}
      position={tooltipPosition}
      remainOnHover={false}
      asPortal={shouldPortalTooltip}
    >
      {iconButton}
    </Tooltip>
  );
};

export default IconButton;

const SIZES = {
  xs: "26px",
  sm: "36px",
  md: "42px",
};

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => SIZES[size]};
  width: ${({ size }) => SIZES[size]};
  background-color: ${({ variant }) => {
    switch (variant) {
      case "destructive-secondary": {
        return COLORS.RED_50;
      }
      case "primary": {
        return COLORS.HALO_BLUE;
      }
      case "secondary": {
        return COLORS.BLUE_LIGHT_250;
      }
      case "fifth": {
        return COLORS.WHITE;
      }
      case "magic": {
        return COLORS.PINK_50;
      }
      default: {
        return "transparent";
      }
    }
  }};
  border: ${({ variant }) => (variant === "fifth" ? `0.5px solid ${COLORS.NEUTRAL_300}` : "none")};
  border-radius: 4px;
  margin: ${({ margin }) => margin};

  &:hover {
    background-color: ${({ variant }) => {
      switch (variant) {
        case "destructive-secondary":
        case "destructive-ghost": {
          return COLORS.RED_100;
        }
        case "secondary": {
          return COLORS.BLUE_LIGHT_400;
        }
        case "ghost": {
          return COLORS.BLUE_LIGHT_300;
        }
        case "fourth": {
          return COLORS.NEUTRAL_200;
        }
        case "fifth": {
          return COLORS.NEUTRAL_50;
        }
        case "ghost-white": {
          return `${COLORS.WHITE}19`;
        }
        case "magic": {
          return COLORS.PINK_100;
        }
        default: {
          return COLORS.BLUE_600;
        }
      }
    }};
  }

  &:active {
    outline: none;
    background-color: ${({ variant }) => {
      switch (variant) {
        case "destructive-secondary":
        case "destructive-ghost": {
          return COLORS.RED_200;
        }
        case "secondary": {
          return COLORS.BLUE_LIGHT_500;
        }
        case "ghost": {
          return COLORS.BLUE_LIGHT_400;
        }
        case "fourth": {
          return COLORS.NEUTRAL_300;
        }
        case "fifth": {
          return COLORS.NEUTRAL_100;
        }
        case "ghost-white": {
          return `${COLORS.WHITE}33`;
        }
        case "magic": {
          return COLORS.PINK_200;
        }
        default: {
          return COLORS.BLUE_650;
        }
      }
    }};
  }

  &:focus:not(:active) {
    outline: 2px solid ${COLORS.WHITE};
    box-shadow: 0 0 0 3px ${COLORS.HALO_BLUE};

    ${({ variant }) =>
      variant === "ghost-white" &&
      `
        outline: none;
        box-shadow: 0 0 0 1px ${COLORS.HALO_BLUE};
        background-color: ${COLORS.WHITE}19;
    `}

    ${({ variant }) =>
      variant.includes("destructive") &&
      `
        outline: none;
        box-shadow: 0 0 0 1px ${COLORS.RED};
        background-color: ${COLORS.RED_100};
    `}
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;
