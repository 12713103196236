import { Icon } from "@components/library";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import styled from "styled-components";

interface Props {
  stepNumber: number;
  label: string;
  onClick?: () => void;
  isCurrent: boolean;
  isComplete: boolean;
  isDisabled?: boolean;
  needsUpdate?: boolean;
  formVariant: "request" | "proposal";
}

const ProgressStep = ({
  stepNumber,
  label,
  onClick,
  isCurrent,
  isComplete,
  isDisabled,
  needsUpdate,
  formVariant,
}: Props) => {
  let stepStyle: "default" | "current" | "complete" | "needsUpdate" = "default";
  if (isCurrent) stepStyle = "current";
  else if (needsUpdate) stepStyle = "needsUpdate";
  else if (isComplete) stepStyle = "complete";

  return (
    <Container
      type="button"
      onClick={onClick}
      stepStyle={stepStyle}
      formVariant={formVariant}
      disabled={isDisabled}
      data-testid={`step-${stepNumber}`}
    >
      {(() => {
        if (stepStyle === "complete")
          return <Icon name="Success" size="lg" color={COLORS.GREEN} data-testid="complete-icon" />;
        if (stepStyle === "needsUpdate") return Attention;
        return <StepNumber stepStyle={stepStyle}>{stepNumber}</StepNumber>;
      })()}
      <span>{label}</span>
    </Container>
  );
};

export default ProgressStep;

const Container = styled.button`
  display: flex;
  justify-content: ${({ formVariant }) => (formVariant === "proposal" ? "center" : "flex-start")};
  align-items: center;
  gap: ${({ formVariant }) => (formVariant === "proposal" ? 6 : 12)}px;
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: left;
  color: ${({ stepStyle, formVariant }) => {
    if (stepStyle === "current") return COLORS.HALO_BLUE;
    if (stepStyle === "complete") {
      return formVariant === "proposal" ? COLORS.GREEN : COLORS.BLACK;
    }
    if (stepStyle === "needsUpdate") return COLORS.ORANGE_900;
    return COLORS.NEUTRAL_500;
  }};
  ${({ formVariant }) => {
    if (formVariant === "proposal") return FONTS.MEDIUM_1;
    return FONTS.MEDIUM_2;
  }};
  &:focus:not(:active) {
    outline: none;
    & > span {
      ${({ stepStyle, formVariant }) =>
        formVariant !== "proposal" &&
        `
          padding-top: 1px;
          border-bottom: 1px solid ${(() => {
            if (stepStyle === "current") return COLORS.HALO_BLUE;
            if (stepStyle === "complete") return COLORS.BLACK;
            if (stepStyle === "needsUpdate") return COLORS.ORANGE_900;
            return COLORS.NEUTRAL_500;
          })()};
      `}
    }
  }
  ${({ formVariant }) =>
    formVariant === "proposal" &&
    `
      ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
        flex-direction: column;
        gap: 0;
        font-size: 14px;
      }
  `}
  ${({ onClick }) => !onClick && "cursor: default;"}
`;
const StepNumber = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  margin: 4px;
  border-radius: 50%;
  background-color: ${COLORS.NEUTRAL_200};
  ${({ stepStyle }) =>
    stepStyle === "current" &&
    `
      border: 1.5px solid ${COLORS.HALO_BLUE};
      background-color: transparent;
    `}
  ${FONTS.SEMIBOLD_2};
`;

// This doesn't quite match any icons from the design system and doesn't appear to be used anywhere else
const Attention = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="error-icon"
  >
    <circle cx="16" cy="16" r="12" fill={COLORS.ORANGE_300} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.125 16.75L17.125 10H14.875L14.875 16.75H17.125ZM16 19C15.1716 19 14.5 19.6716 14.5 20.5C14.5 21.3284 15.1716 22 16 22C16.8284 22 17.5 21.3284 17.5 20.5C17.5 19.6716 16.8284 19 16 19Z"
      fill={COLORS.ORANGE_900}
    />
  </svg>
);
