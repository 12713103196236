import { suggestions_keywords_path } from "@routes/routes";
import { KeywordType } from "@tsTypes/keywords";
import { RequestableType } from "@tsTypes/requests";
import appsignal from "@utils/appsignal";
import api from "./request";

export const getDisciplines = async (): Promise<string[]> => {
  const response = await api.get("/disciplines");
  return response.data;
};

export const getAreasOfExpertise = async (): Promise<string[]> => {
  const response = await api.get("/areas_of_expertise");
  return response.data;
};

export const getKeywords = async (): Promise<{
  disciplines: string[];
  areas_of_expertise: string[];
}> => {
  const response = await api.get("/keywords");
  return response.data;
};

export const createKeyword = async (
  name: string,
  type: "Discipline" | "AreaOfExpertise"
): Promise<void> => {
  try {
    await api.post("/keywords", { name, type });
  } catch (err) {
    appsignal.sendError(err, (span) => {
      span.setAction("keywords#create");
      span.setTags({
        name,
      });
    });
  }
};

export const getKeywordSuggestionsBySimilarity = async (
  keywords: string[],
  type: "All" | "Discipline" | "AreaOfExpertise"
): Promise<string[]> => {
  try {
    const response = await api.get("/keywords/suggestions", {
      params: {
        keywords,
        type,
        by_similarity: true,
      },
    });

    if (!response) return [];

    return response.data.suggestions;
  } catch (err) {
    if (err?.code !== "ECONNABORTED") {
      appsignal.sendError(err, (span) => {
        span.setAction("keywords#getKeywordsSuggestion");
        span.setTags({
          keywords: keywords.join(";"),
        });
      });
    }
    return [];
  }
};

export const getKeywordSuggestionsByContent = async (
  requestData: {
    title: string;
    request_solutions: string[];
    request_requirements: {
      description: string;
      allowAttachments: boolean;
    }[];
    requestable_type: RequestableType;
    requestable: {
      background: string;
      looking_for: string;
    };
  },
  keywordType: KeywordType
) => {
  const response = await api.get(
    suggestions_keywords_path({
      request_data: requestData,
      keyword_type: keywordType,
    })
  );
  return response.data.suggestions;
};
