import { Icon } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  shouldDisplay?: boolean;
  text: ReactNode;
  font?: string;
  type?: "info" | "attention-dark" | "attention-orange" | "ghost" | "magic";
  width?: string;
  margin?: string;
  padding?: string;
  hideIcon?: boolean;
  "data-testid"?: string;
}

const InfoBanner = ({
  shouldDisplay = true,
  text,
  font,
  type = "info",
  width = "fit-content",
  margin,
  padding = "16px",
  hideIcon = false,
  "data-testid": dataTestId,
}: Props) => {
  let icon: ReactNode = (
    <Icon name="Info Small" color={COLORS.BLACK} size="sm" margin="0 8px 0 0" />
  );
  if (type === "ghost") {
    icon = <Icon name="Attention" color={COLORS.BLACK} margin="0 8px 0 0" />;
  }
  if (type.includes("attention")) {
    icon = (
      <Icon
        name="Attention"
        color={type === "attention-dark" ? COLORS.BLACK : COLORS.ORANGE_900}
        margin="0 8px 0 0"
      />
    );
  }
  if (type === "magic") {
    icon = <Icon name="Magic Full" color={COLORS.PINK_800} margin="0 8px 0 0" />;
  }

  return shouldDisplay ? (
    <Container
      width={width}
      font={font}
      margin={margin}
      padding={padding}
      type={type}
      data-testid={dataTestId}
    >
      {!hideIcon && icon}
      {text}
    </Container>
  ) : null;
};

export default InfoBanner;

const Container = styled.div`
  display: flex;
  width: ${({ width }) => width};
  ${({ margin }) => margin && `margin: ${margin}`};
  padding: ${({ padding }) => padding};
  border-radius: 6px;
  ${({ type }) => {
    switch (type) {
      case "info":
        return `
          color: ${COLORS.BLACK};
          background-color: ${COLORS.NEUTRAL_100};
          ${FONTS.REGULAR_3}
        `;
      case "attention-orange":
        return `
          color: ${COLORS.ORANGE_900};
          background-color: ${COLORS.ORANGE_100};
          ${FONTS.MEDIUM_2}
        `;
      case "attention-dark":
        return `
          color: ${COLORS.BLACK};
          background-color: ${COLORS.NEUTRAL_100};
          ${FONTS.MEDIUM_2}
        `;
      case "ghost":
        return `
          color: ${COLORS.BLACK};
          background-color: ${COLORS.WHITE};
          ${FONTS.MEDIUM_2}
        `;
      case "magic": {
        return `
          color: ${COLORS.PINK_800};
          background-color: ${COLORS.PINK_50};
          ${FONTS.REGULAR_2}
        `;
      }
    }
  }}
  ${({ font }) => font && font};
`;
